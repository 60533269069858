import React, { useState } from 'react';
import { Tabs, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import FileMode from './file-mode';
import TextMode from './text-mode';

const ChineseAI = () => {
  const [activeTab, setActiveTab] = useState('1');
  return (
    <div>
      <Tabs defaultActiveKey="1" onChange={(key) => setActiveTab(key)}>
        <Tabs.TabPane
          tab={
            <>
              文件模式
              <Tooltip title="请上传word文档，把有AI率的文字标成红色RGB(255,0,0)">
                <QuestionCircleOutlined style={{ marginLeft: 4 }} />
              </Tooltip>
            </>
          }
          key="1" />
        <Tabs.TabPane tab="文本模式" key="2" />
      </Tabs>
      {activeTab === '1' && <FileMode />}
      {activeTab === '2' && <TextMode />}
    </div>
  );
};

export default ChineseAI;