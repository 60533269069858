import React, { useState } from 'react';
import { Input, Button, message } from 'antd';
import { ArrowRightOutlined, CopyOutlined, DeleteOutlined } from '@ant-design/icons';
import http from '../../../utils/api';
import styles from './index.module.css';
import copy from 'copy-to-clipboard';
const { TextArea } = Input;

const TextMode = React.memo(() => {
  const [inputText, setInputText] = useState('');
  const [outputText, setOutputText] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);

  // 计算字数
  const countWords = (text: string) => {
    return text.match(/[\u4e00-\u9fa5]/g)?.length || 0; // 匹配中文字符
  };

  // 检查输入是否有效
  const isValidInput = () => {
    const wordCount = countWords(inputText);
    return wordCount > 0 && wordCount <= 500;
  };

  // 处理文本
  const handleProcess = async () => {
    try {
      setOutputText('');
      setIsProcessing(true);
      const response = await http.post('/transfer_text_chinese', {
        text: inputText
      });
      setOutputText(response.data.result);
      message.success('处理成功！');
    } catch (error) {
      message.error('处理失败：' + (error.message || '未知错误'));
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className={styles.textMode}>
      <div className={styles.inputContent}>
        <div className={styles.title}>
          <span>输入中文</span>
          <div>
            <DeleteOutlined
              className={styles.copyIcon}
              onClick={() => {
                setInputText('');
                setOutputText('');
              }}
            />
            <CopyOutlined
              className={styles.copyIcon}
              onClick={() => {
                if (copy(inputText)) {
                  message.success('复制成功');
                } else {
                  message.error('复制失败');
                }
              }}
            />
          </div>
        </div>
        <TextArea
          value={inputText}
          onChange={(e) => {
            setInputText(e.target.value);
          }}
          placeholder="请输入中文文本（最多500个字）"
          rows={20}
        />
        <div
          className={styles.countWords}
          style={{
            color: countWords(inputText) > 500 ? '#ff4d4f' : '#666'
          }}>
          当前字数：{countWords(inputText)}/500
        </div>
      </div>

      <Button
        type="primary"
        icon={<ArrowRightOutlined />}
        disabled={!isValidInput() || isProcessing}
        loading={isProcessing}
        onClick={handleProcess}
        style={{
          height: '40px',
          width: '120px',
          borderRadius: '20px',
          fontSize: '14px'
        }}
      >
        {isProcessing ? '处理中' : '开始执行'}
      </Button>

      <div className={styles.inputContent}>
        <div className={styles.title}>
          <span>结果</span>
          <CopyOutlined
            className={styles.copyIcon}
            onClick={() => {
              if (copy(outputText)) {
                message.success('复制成功');
              } else {
                message.error('复制失败');
              }
            }}
          />
        </div>
        <TextArea
          value={outputText}
          readOnly
          rows={20}
        />
        <div
          className={styles.countWords}
          style={{
            color: '#666'
          }}>
          字数：{countWords(outputText)}
        </div>
      </div>
    </div>
  );
});

export default TextMode;