import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Register from './pages/register';
import Login from './pages/login';
import Home from './pages/home';
import EnglishAI from './pages/english-ai';
import More from './pages/more';
import ChineseAI from './pages/chinese-ai';

const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Home />}>
          <Route index element={<EnglishAI />} />
          <Route path="english-ai" element={<EnglishAI />} />
          <Route path="chinese-ai" element={<ChineseAI />} />
          <Route path="more" element={<More />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default AppRoutes;