import React, { useState, useEffect, useRef } from 'react';
import { Button, Table, message, Select, Space, Tooltip } from 'antd';
import { PlusOutlined, ArrowRightOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import Dragger from 'antd/es/upload/Dragger';
import http from '../../../utils/api';
import styles from './index.module.css';
import { getColumns } from './columns';

interface TableListItem {
  create_time: string;
  filename: string;
  download_url: string;
  model_type: number; // 模式类型 1：模式1 2：模式2 0：未知
  progress: number; // 进度
  status: string; // 状态
  task_id: string;
  processed: number; // 已处理字数
  total: number; // 总字数
}

const FileMode = () => {
  const [tableList, setTableList] = useState<TableListItem[]>([]);
  const [processMode, setProcessMode] = useState('/process_v1'); // 处理模式
  const [fileList, setFileList] = useState<any[]>([]); // 上传的文件列表
  const [loading, setLoading] = useState(false); // 表格loading
  const pollingTimerRef = useRef<NodeJS.Timer | null>(null); // 轮询的timer
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0
  });

  // 检查是否所有任务都已完成
  const isAllTasksCompleted = (list: TableListItem[]) => {
    return list.every(item =>
      item.status === 'completed' || item.status === 'error'
    );
  };

  // 获取表格数据
  const getTableData = async (page = 1, pageSize = 10) => {
    try {
      setLoading(true);
      const response = await http.get('/process_list', {
        params: {
          page,
          pageSize
        }
      });
      const list = response.data.list;
      setTableList(list);
      // 更新分页信息
      setPagination(prev => ({
        ...prev,
        current: page,
        pageSize,
        total: response.data.total
      }));

      if (isAllTasksCompleted(list)) {
        if (pollingTimerRef.current) {
          clearInterval(pollingTimerRef.current);
          pollingTimerRef.current = null;
        }
      }
    } catch (error) {
      message.error('获取表格数据失败');
      // 发生错误时也停止轮询
      if (pollingTimerRef.current) {
        clearInterval(pollingTimerRef.current);
        pollingTimerRef.current = null;
      }
    } finally {
      setLoading(false);
    }
  };

  // 处理分页变化
  const handleTableChange = (page: number, pageSize: number) => {
    getTableData(page, pageSize);
  };

  // 开始轮询
  const startPolling = () => {
    // 设置定时器
    const timer = setInterval(getTableData, 5000);
    if (pollingTimerRef.current) {
      clearInterval(pollingTimerRef.current);
    }
    console.log('timer--', timer);
    pollingTimerRef.current = timer;
  };

  // 开始执行
  const handleProcess = async () => {
    try {
      await http.get(processMode, { params: { language: 'english' } });
      message.success('任务已开始处理');
      startPolling();
      setFileList([]);
    } catch (error) {
      message.error('处理失败：' + (error.message || '未知错误'));
    }
  };

  useEffect(() => {
    getTableData();
    // 组件卸载时清除定时器
    return () => {
      if (pollingTimerRef.current) {
        clearInterval(pollingTimerRef.current);
      }
    };
  }, []);

  const columns = getColumns();

  return (
    <div className={styles.fileMode}>
      <Space align="start" className={styles.uploadSpace}>
        <div className={styles.stepContent}>
          <div className={styles.stepTitle}>第一步</div>
          <Dragger
            name="file"
            multiple={false}
            fileList={fileList}
            onChange={(info) => {
              setFileList(info.fileList.slice(-1));
              const { status, response } = info.file;
              if (status === 'done') {
                message.success('文件上传成功！选择处理模式后点击执行');
              } else if (status === 'error') {
                message.error('文件上传失败，请重试！');
              }
            }}
            customRequest={async ({ file, onSuccess, onError }) => {
              try {
                const formData = new FormData();
                formData.append('file', file);
                const response = await http.post('/upload', formData, {
                  headers: { 'Content-Type': 'multipart/form-data' },
                });
                onSuccess(response.data);
              } catch (error) {
                onError(error);
              }
            }}
            style={{
              width: '240px',
              border: '1px dashed #1890ff',
              borderRadius: '12px',
              background: '#f0f8ff',
              cursor: 'pointer',
            }}
          >
            <PlusOutlined style={{ fontSize: '30px', color: '#1890ff' }} />
            <div style={{
              color: '#666',
              fontSize: '16px',
            }}>点击或拖拽上传文件</div>
          </Dragger>
        </div>

        <ArrowRightOutlined className={styles.arrowRight} />
        <div className={styles.stepContent}>
          <div className={styles.stepTitle}>
            第二步
            <Tooltip title={
              <div>
                <div>模式1：</div>
                <div>保留红色文字，蓝色文字另起一个段落插入到红色文字的下方，最终还需要人工把红色文字删除</div>
                <div style={{ marginTop: '8px' }}>模式2：</div>
                <div>蓝色文字直接替换红色文字</div>
              </div>
            }>
              <QuestionCircleOutlined style={{ marginLeft: '4px', cursor: 'pointer' }} />
            </Tooltip>
          </div>
          <Select
            style={{
              width: '200px',
              height: '40px',
              marginTop: '20px'
            }}
            value={processMode}
            onChange={setProcessMode}
            options={[
              { label: '模式1', value: '/process_v1' },
              { label: '模式2', value: '/process_v2' },
            ]}
          />
        </div>

        <ArrowRightOutlined className={styles.arrowRight} />
        <div className={styles.stepContent}>
          <div className={styles.stepTitle}>第三步</div>
          <Button
            type="primary"
            disabled={!fileList.length}
            onClick={handleProcess}
            className={styles.progressButton}
          >
            开始执行
          </Button>
        </div>
      </Space>

      <Table
        columns={columns}
        dataSource={tableList}
        style={{ marginTop: 16 }}
        loading={loading}
        rowKey="task_id"
        pagination={{
          ...pagination,
          showSizeChanger: true, // 显示页码大小选择器
          showQuickJumper: true, // 显示快速跳转
          showTotal: (total) => `共 ${total} 条记录`, // 显示总记录数
          onChange: handleTableChange, // 页码变化回调
        }}
      />
    </div>
  )
};

export default FileMode;