import React, { useState, useEffect } from 'react';
import { Link, Route, Routes } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import { FileTextOutlined, TranslationOutlined } from '@ant-design/icons';
import EnglishAI from '../english-ai';
import More from '../more';
import styles from './index.module.css';
import ChineseAI from '../chinese-ai';

const { Header, Sider, Content } = Layout;

const Home = () => {
  const [userInfo, setUserInfo] = useState({ nickname: '', balance: 0 });

  useEffect(() => {
    // 获取用户信息
    // axios.get('/api/user-info').then(response => {
    //   setUserInfo(response.data);
    // }).catch(error => {
    //   console.error('获取用户信息失败:', error);
    // });
  }, []);

  return (
    <Layout className={styles.home}>
      <Header className={styles.header}>
        <div>
          <img src="/logo.png" alt="网站logo" className={styles.logo} />
        </div>
        <div>
          <span>{userInfo.nickname || '默认用户'}</span>
          <span style={{ color: 'red', marginLeft: 16 }}>余额: {userInfo.balance}字</span>
        </div>
      </Header>
      <Layout style={{ marginTop: "16px" }}>
        <Sider width={200} style={{ background: '#fff' }}>
          <Menu
            mode="inline"
            defaultSelectedKeys={['1']}
            style={{
              marginTop: '30px'
            }}
          >
            <Menu.Item key="1" icon={<FileTextOutlined />}>
              <Link to="/english-ai">降低英文AI率</Link>
            </Menu.Item>
            <Menu.Item key="2" icon={<FileTextOutlined />}>
              <Link to="/chinese-ai">降低中文AI率</Link>
            </Menu.Item>
            <Menu.Item key="100" icon={<TranslationOutlined />}>
              <Link to="/more">更多功能</Link>
            </Menu.Item>
          </Menu>
        </Sider>
        <Content className={styles.rightContent}>
          <Routes>
            <Route path="/english-ai" element={<EnglishAI />} />
            <Route path="/chinese-ai" element={<ChineseAI />} />
            <Route path="/more" element={<More />} />
          </Routes>
        </Content>
      </Layout>
    </Layout>
  );
};

export default Home;