import React, { useState, useEffect } from 'react';

const More = () => {
  return (
    <div>
      更多功能敬请期待
    </div>
  );
};

export default More;