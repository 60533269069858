import React from 'react';
import { Form, Input, Button, Typography, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

const { Title } = Typography;

const Login = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const onFinish = async (values: any) => {
    try {
      const response = await axios.post('/api/login', values);
      if (response.data.success) {
        message.success('登录成功');
        // 登录成功后可以进行页面跳转
        navigate('/list');
      } else {
        message.error(response.data.message || '登录失败');
      }
    } catch (error) {
      console.error('登录错误:', error);
      message.error('登录失败,请稍后再试');
    }
  };

  return (
    <div style={{ maxWidth: 300, margin: '0 auto', padding: '20px 0' }}>
      <Title level={2} style={{ textAlign: 'center' }}>用户登录</Title>
      <Form
        form={form}
        name="login"
        onFinish={onFinish}
      >
        <Form.Item
          name="username"
          rules={[{ required: true, message: '请输入用户名!' }]}
        >
          <Input prefix={<UserOutlined />} placeholder="用户名" />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[{ required: true, message: '请输入密码!' }]}
        >
          <Input.Password prefix={<LockOutlined />} placeholder="密码" />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
            登录
          </Button>
        </Form.Item>
      </Form>
      <div style={{ textAlign: 'center' }}>
        还没有账号? <Link to="/register">立即注册</Link>
      </div>
    </div>
  );
};

export default Login;