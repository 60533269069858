import { message } from 'antd';
import axios from 'axios';

// 配置axios的基础URL
const http = axios.create({
  // baseURL: 'http://127.0.0.1:5000',
  baseURL: 'http://8.146.199.240:5000',
  // 允许跨域携带cookie
  withCredentials: true
});

http.interceptors.response.use(
  (response) => {
    console.log('响应response', response)
    // 如果响应类型是 blob，直接返回
    if (response.config.responseType === 'blob') {
      return response;
    }
    
    // 其他响应的处理逻辑保持不变
    if (response.data.code === 0) {
      return response.data;
    } else {
      message.error(response.data.message);
      return Promise.reject(response.data);
    }
  },
  (error) => {
    message.error(error.message);
    return Promise.reject(error);
  }
);

export default http;
