import { Button, message, Space } from "antd";
import http from "../../../utils/api";

export const getColumns = () => {
  const columns = [
    {
      title: '任务ID',
      dataIndex: 'task_id',
      key: 'task_id',
      width: 220,
    },
    {
      title: '模式',
      dataIndex: 'model_type',
      key: 'model_type',
      width: 120,
      render: (model_type: number) => {
        if(model_type === 1) {
          return '模式1'
        } else if(model_type === 2) {
          return '模式2'
        }
        return '未知';
      }
    },
    {
      title: '文件名',
      dataIndex: 'filename',
      key: 'filename',
      width: 150,
    },
    {
      title: '总字数',
      dataIndex: 'total',
      key: 'total',
      width: 100,
    },
    {
      title: '处理进度',
      dataIndex: 'progress',
      key: 'progress',
      width: 120,
      render: (progress: number,record: any) => {
        if(record?.model_type === 2 && record?.status === 'completed') {
          return '100%';
        }
        return `${progress}%`;
      },
    },
    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      width: 120,
      render: (status: string) => {
        const statusMap = {
          'processing': '处理中',
          'completed': '已完成',
          'error': '失败，请重新上传'
        };
        return statusMap[status] || status;
      },
    },
    {
      title: '创建时间',
      dataIndex: 'create_time',
      key: 'create_time',
      width: 250,
    },
    {
      title: '操作',
      key: 'action',
      width: 150,
      render: (_: any, record: any) => (
        <Space>
          {record.download_url && record.status === 'completed' && (
            <Button
              type="link"
              onClick={async () => {
                try {
                  const response = await http.get(`/download_result/${record.task_id}`, {
                    responseType: 'blob' // 关键修改：设置响应类型为blob
                  });

                  // 直接使用响应数据创建Blob
                  const blob = new Blob([response.data], {
                    type: response.headers['content-type'] // 使用响应头中的content-type
                  });

                  const url = window.URL.createObjectURL(blob);
                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute('download', `降AI率-${record?.filename}`);
                  document.body.appendChild(link);
                  link.click();

                  // 清理
                  link.remove();
                  window.URL.revokeObjectURL(url);
                } catch (error) {
                  message.error('下载失败：' + error.message);
                }
              }}>
              下载结果
            </Button>
          )}
          {/* <Button 
            onClick={() => handleReprocess(record.task_id)}
            disabled={record.status === 'processing'}
          >
            重新处理
          </Button> */}
        </Space>
      ),
    },
  ];
  return columns;
};